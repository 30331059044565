import switchCards from "./componentsEn/switchCards";
import swiperBlock from "./componentsEn/swiperBlock";
import errorMessages from "./componentsEn/errorMessages";
import contactForm from "./componentsEn/contactForm";
import contactCards from "./componentsEn/contactCards";
import warehouseCards from "./componentsEn/warehouseCards";
import footer from "./componentsEn/footer";
import meta from "./componentsEn/meta";
import cookies from "./componentsEn/cookies";

const en = {
  logo: require("@/assets/img/logo.svg"),
  mail: "{account}{'@'}{domain}",
  header: {
    logo: `
      <span>Logistics</span>
      <span>Centre</span>
      <span>Zalog</span>
    `,
  },
  banner: {
    img: {
      src: require("@/assets/img/foto1.webp"),
      fb: require("@/assets/img/foto1.jpg"),
      alt: "Banner image",
    },
    titleTop: "NEW",
    title:
      "A technologically sophisticated and energy efficient logistics centre",
    "content-left":
      "A logistics centre in the heart of Slovenia and at the crossroads of regional and international transit supply chains.",
    "content-right":
      "In need of new warehouse facilities or logistics services?",
    button: "SEND AN INQUIRY",
  },
  "basic-card": [
    {
      title: "NEW LOGISTIC CENTRE",
      main: `
        <p>In the middle of 2024, the BTC Company expanded its existing capacities of more than 100,000 pallet spaces with an additional 50,000 in the technologically sophisticated and energy efficient Logistics Centre Zalog. Located in Ljubljana, the capital of Slovenia, and strategically situated in the immediate vicinity of the Slovenian motorway network, Logistics Centre Zalog presents an excellent starting point for optimising local and regional transport routes.</p>
        <p>The warehouse facility is intended mainly for the storage and handling of fast-moving consumer goods (FMCG), with the possibility of adjusting capacities for companies operating in the field of e-commerce.</p>
      `,
      side: `
        <li><b>Exceptional location at the crossroads of European transport routes.</b></li>
        <li><b>Excellent starting point for exporting goods to other Central European countries.</b></li>
        <li><b>Possibility of cooperation in the field of logistics for e-commerce.</b></li> 
        <li><b>Our quality services are trusted by global business partners.</b></li>  
      `,
    },
  ],
  swiperBlock: swiperBlock,
  map: {
    title: "Location",
    name: "BTC Logistics Centre Zalog",
    address: "Agrokombinatska cesta 63",
    city: "1000 Ljubljana",
    tel: "+386 1 585 11 88",
    main: `
      <h3> Strategic. Transit. Intermodal.</h3>
      <ul>
        <li>At the cross road of supply routes and international transit supply chains (Central and Eastern Europe and the Adriatic Region),</li>
        <li>in the immediate vicinity of a railway container terminal (7 km),</li>
        <li>easy connection to the international cargo port Luka Koper (110 km) and the Ljubljana airport (38 km),</li>
        <li>in the immediate vicinity of BTC City, one of the largest shopping, business, leisure, entertainment and culture centres in Europe and the headquarters of the BTC company.</li>
      </ul>
      <h5>Strategic location at the cross road of European transport routes.</h5>
    `,
  },
  contacts: {
    title: "Get in touch",
    cards: contactCards,
  },
  companySwiper: {
    title: "References",
  },
  switchCards: switchCards,
  errorMessages: errorMessages,
  contactForm: contactForm,
  warehouseTitle: "BTC Logistics manages",
  warehouse: warehouseCards,
  footer: footer,
  meta: meta,
  cookies: cookies,
};

export default en;
