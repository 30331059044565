const swiperBlock = [
  {
    img: {
      src: require("@/assets/img/foto5.webp"),
      fb: require("@/assets/img/foto5.jpg"),
      alt: "Image1",
    },
    infographic: require("@/assets/img/icons/palete.svg"),
    stat: "40.000 m²",
    info: "of warehouse facilities",
  },
  {
    img: {
      src: require("@/assets/img/foto2.webp"),
      fb: require("@/assets/img/foto2.jpg"),
      alt: "Image2",
    },
    infographic: require("@/assets/img/icons/solarplant.svg"),
    stat: "6.000 m²",
    info: "photovoltaic power station",
  },
  {
    img: {
      src: require("@/assets/img/foto3.webp"),
      fb: require("@/assets/img/foto3.jpg"),
      alt: "Image3",
    },
    infographic: require("@/assets/img/icons/eco.svg"),
    stat: "Green roof",
    info: "and heating with a heat pump",
  },
  {
    img: {
      src: require("@/assets/img/foto4.webp"),
      fb: require("@/assets/img/foto4.jpg"),
      alt: "Image4",
    },
    infographic: require("@/assets/img/icons/strategic.svg"),
    stat: "Strategic",
    info: "location",
  },
  {
    img: {
      src: require("@/assets/img/foto6.webp"),
      fb: require("@/assets/img/foto6.jpg"),
      alt: "Image5",
    },
    infographic: require("@/assets/img/icons/technology2.svg"),
    stat: "Inovative",
    info: "technology",
  },
];

export default swiperBlock;
