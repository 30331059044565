/* eslint-disable */
const warehouseCards = [
  {
    img: require("@/assets/img/icons/palete.svg"),
    stat: "130,000 m²",
    name: "of closed warehousing capacities",
  },
  {
    img: require("@/assets/img/icons/area.svg"),
    stat: "5,500 m²",
    name: "of warehousing capacities with a temperature regime",
  },
  {
    img: require("@/assets/img/icons/forklift.svg"),
    stat: "11,000",
    name: "pallets of goods transported daily",
  },
  {
    img: require("@/assets/img/icons/endcustomers.svg"),
    stat: "1,200",
    name: "end-customers for delivery daily",
  },
  {
    img: require("@/assets/img/icons/vehicles.svg"),
    stat: "300",
    name: "vehicles with loaded goods daily",
  },
  {
    img: require("@/assets/img/icons/officespace.svg"),
    stat: "5,500 m²",
    name: "of office space",
  },
];

export default warehouseCards;
