<template>
  <BannerBlock />
  <BasicCard :title="$t('basic-card[0].title')" class="mb-8">
    <div v-html="$t('basic-card[0].main')" class="mb-6"></div>
    <template #side>
      <ul v-html="$t('basic-card[0].side')"></ul>
    </template>
  </BasicCard>
  <SwiperBlock />
  <InteractiveMap :key="$i18n.locale" />
  <SwitchCard
    v-for="card in messages[locale].switchCards"
    :key="card.title"
    :title="card.title"
    :reverse="card.reverse ?? false"
  >
    <div v-html="card.content"></div>
    <template #side>
      <PictureVue :img="card.img" />
    </template>
  </SwitchCard>
  <WarehouseStats />
  <CompanyList />
  <ContactsBlock />
  <ContactUs />
  <CookieConsent />
</template>

<script setup>
import BannerBlock from "@/components/blocks/BannerBlock.vue";
import BasicCard from "@/components/blocks/BasicCard.vue";
import CompanyList from "@/components/blocks/CompanyList.vue";
import ContactsBlock from "@/components/blocks/ContactsBlock.vue";
import ContactUs from "@/components/blocks/ContactUs.vue";
import InteractiveMap from "@/components/blocks/InteractiveMap.vue";
import SwiperBlock from "@/components/blocks/SwiperBlock.vue";
import SwitchCard from "@/components/blocks/SwitchCard.vue";
import WarehouseStats from "@/components/blocks/WarehouseStats.vue";
import PictureVue from "@/components/elements/PictureVue.vue";
import { useI18n } from "vue-i18n";
import { useHead } from "@vueuse/head";
import { defineAsyncComponent } from "vue";

const CookieConsent = defineAsyncComponent({
  loader: () => import("@/components/layout/CookieConsent.vue"),
  delay: 1000,
});

const { t, messages, locale } = useI18n();

useHead({
  title: () => t("meta.title"),
  meta: [
    {
      name: "description",
      content: () => t("meta.description"),
    },
    {
      property: "og:description",
      content: () => t("meta.description"),
    },
    {
      property: "twitter:description",
      content: () => t("meta.description"),
    },
  ],
});
</script>
