const footer = {
  title: "ABOUT THE BTC COMPANY",
  body: "The BTC Company is considered as one of the leading providers of fast-moving consumer goods (FMCG) logistics services in Slovenia. The knowledge and experience gathered across industries, as well as our multi-channel expertise, provide us with a solid basis for creating comprehensive, flexible and robust solutions that guarantee to deliver the promised results in any business. More than 30 domestic and international companies have already chosen to put their trust in our services",
  terms: "Terms of Use",
  cookie: "Cookie Policy",
  primaryLink: "https://www.btc.si/en/",
  termsLink: "https://www.btc.si/en/terms-of-use/",
};

export default footer;
