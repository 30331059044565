const warehouseCards = [
  {
    img: require("@/assets/img/icons/palete.svg"),
    stat: "130.000 m²",
    name: "zaprtih skladiščnih kapacitet",
  },
  {
    img: require("@/assets/img/icons/area.svg"),
    stat: "5.500 m²",
    name: "skladiščne kapacitete s temperaturnim režimom",
  },
  {
    img: require("@/assets/img/icons/forklift.svg"),
    stat: "11.000",
    name: "prepeljanih palet z blagom dnevno",
  },
  {
    img: require("@/assets/img/icons/endcustomers.svg"),
    stat: "1.200",
    name: "dostav končnim strankam dnevno",
  },
  {
    img: require("@/assets/img/icons/vehicles.svg"),
    stat: "300",
    name: "naloženih tovornih vozil dnevno",
  },
  {
    img: require("@/assets/img/icons/officespace.svg"),
    stat: "5.500 m²",
    name: "pisarniških prostorov",
  },
];

export default warehouseCards;
