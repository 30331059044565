const footer = {
  title: "O BTC",
  body: "Družba BTC velja za enega izmed vodilnih ponudnikov logističnih storitev na področju izdelkov vsakdanje rabe (FMCG) v Sloveniji. Povezovanje pridobljenih znanj in izkušenj v različnih industrijah in večkanalnem poslovanju nam omogoča podlago za oblikovanje celovitih, prilagodljivih in robustnih logističnih rešitev, ki zagotavljajo obljubljeni rezultat na katerem koli področju poslovanja. Našim storitvam zaupa že več kot 30 domačih in mednarodnih podjetij.",
  terms: "Pogoji uporabe",
  cookie: "Politika piškotov",
  primaryLink: "https://www.btc.si/",
  termsLink: "https://www.btc.si/pogoji-uporabe/",
};

export default footer;
