<template>
  <label
    :class="[{ error: errorMessage }, 'checkbox-holder', 'form-field']"
    :for="uuid"
  >
    <div class="checkbox-input">
      <input
        v-bind="$attrs"
        @change="handler($event)"
        :id="uuid"
        :name="name"
        :value="true"
        :checked="checkedValue"
        class="checkbox"
        type="checkbox"
        :aria-describedby="errorMessage ? `${uuid}-error` : null"
        :aria-invalid="errorMessage ? true : null"
      />
      <!-- Check icon -->
      <div class="faux-checkbox error-border">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          aria-hidden="true"
          focusable="false"
        >
          <path
            class="check-stroke"
            fill="none"
            stroke-width="3"
            d="M1.73 12.91l6.37 6.37L22.79 4.59"
          ></path>
        </svg>
      </div>
    </div>

    <p class="checkbox-text">
      {{ label ? label : null }}
      <slot></slot>
    </p>
  </label>

  <BaseErrorMessage v-if="errorMessage" :id="`${uuid}-error`">
    {{ errorMessage }}
  </BaseErrorMessage>
</template>

<script setup>
import UniqueID from "../helpers/UniqueID";
import { useField } from "vee-validate";
import { inject } from "vue";

const uuid = `checkbox-${UniqueID().getID()}`;
const props = defineProps({
  label: {
    type: String,
    default: "",
  },
  name: {
    type: String,
    required: true,
  },
});

const formData = inject("FORM_DATA");
const {
  value: checkedValue,
  errorMessage,
  handleChange,
} = useField(props.name, undefined, {
  type: "checkbox",
});

const handler = function (event) {
  handleChange(event.target.value);
  formData.set(props.name, event.target.value);
};
</script>

<style lang="scss" scoped>
//// Checkbox ////

.checkbox-holder {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row nowrap;
  border: none;

  .checkbox-input {
    position: relative;
    flex: 0 0 24px;
    max-width: 24px;
    height: 24px;
    input {
      position: absolute;
      display: block;
      opacity: 0;
      left: 0;
      top: 0;
      width: 24px;
      height: 24px;
      margin: 0;
      margin-top: inherit;
      cursor: pointer;
      &:checked + .faux-checkbox svg {
        transform: scale(1);
      }
    }
    svg {
      transform: scale(0);
      margin: 2px;
      vertical-align: unset;
    }
    .check-stroke {
      stroke: var(--color-primary, #19a974);
    }
  }
  .faux-checkbox {
    display: block;
    width: 22x;
    height: 22px;
    background-color: #0000;
    border: 2px solid var(--color-base, #e4e4e4);
    border-radius: 3px;
    pointer-events: none;
    user-select: none;
  }
  .checkbox-text {
    flex: 0 1 calc(100% - 24px);
    margin: 0;
    text-align: left;
    padding-left: 10px;
    font-size: 0.9rem;
    line-height: 1.1rem;
    a {
      color: var(--color-primary, inherit);
    }
  }
}

.error .faux-checkbox {
  border-color: var(--color-error);
}
</style>
