const contactCards = [
  {
    img: {
      src: require("@/assets/img/BTC_Pohar.webp"),
      fb: require("@/assets/img/BTC_Pohar.jpg"),
      alt: "Matevž Pohar",
    },
    name: "Matevž Pohar",
    position: "Head of sales of BTC Logistics Centre",
    tel: "+386 1 585 11 88",
    mail: "Matevz.pohar@btc.si",
  },
  {
    img: {
      src: require("@/assets/img/BTC_kosir.webp"),
      fb: require("@/assets/img/BTC_kosir.jpg"),
      alt: "Robi Košir",
    },
    name: "Robi Košir",
    position: "Director of BTC Logistics Centre",
    tel: "+386 1 585 11 88",
    mail: "Robi.kosir@btc.si",
  },
];

export default contactCards;
