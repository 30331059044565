const contactForm = {
  title: "Send an inquiry",
  para: "Let us know your preferences and wishes about our services. We will answer you as soon as possible.",
  errorMessage: {
    title: "Error",
    message: "Something went wrong, try again.",
  },
  successMessage: {
    title: "Sent",
    message: "Messsage was successfully sent!",
  },
  buttonSubmit: "Send",
  fullname: "Full name / Company name",
  email: "E-mail address",
  yourMsg: "Your message",
  terms: "I agree with the terms.",
  dialogClose: "Close",
};

export default contactForm;
