<template>
  <div
    class="warehouse-stats container relative bg-gray-100 pb-6 pt-11"
    :key="$i18n.locale"
  >
    <div>
      <h2 class="h3 blue-dark">{{ $t("warehouseTitle") }}</h2>
      <div class="separator bg-green mt-5 mb-8"></div>
    </div>
    <div class="background absolute w-full h-full"></div>
    <div ref="swiper" class="swiper">
      <div class="swiper-wrapper">
        <WarehouseSlide
          v-for="card in messages[locale].warehouse"
          :key="card.name"
          :card="card"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import WarehouseSlide from "../elements/WarehouseSlide.vue";
import { useI18n } from "vue-i18n";
import { onMounted, onUpdated, ref } from "vue";
import Swiper, { Autoplay } from "swiper";

const { messages, locale } = useI18n();
const swiper = ref();

onMounted(() => {
  new Swiper(swiper.value, {
    modules: [Autoplay],
    slidesPerView: "auto",
    spaceBetween: 40,
    loop: true,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
  });
});

onUpdated(() => {
  new Swiper(swiper.value, {
    modules: [Autoplay],
    slidesPerView: "auto",
    spaceBetween: 40,
    loop: true,
    autoplay: {
      delay: 2500,
    },
  });
});
</script>
