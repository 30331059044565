import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import { createHead } from "@vueuse/head";
import { pageTitle } from "vue-page-title";

import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";

import si from "./lang/si";
import en from "./lang/en";
import App from "./App.vue";

window.cookieconsent = require("cookieconsent");

const app = createApp(App);
const head = createHead();

const requireInput = require.context(
  "./components/form/inputs",
  false,
  /Base[A-Z]\w+\.(vue|js)$/
);

const requireElement = require.context(
  "./components/form/elements",
  false,
  /Base[A-Z]\w+\.(vue|js)$/
);

const requireWizard = require.context(
  "./components/form/wizard",
  false,
  /Base[A-Z]\w+\.(vue|js)$/
);

requireInput.keys().forEach((fileName) => {
  const componentConfig = requireInput(fileName);

  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, "$1"))
  );

  app.component(componentName, componentConfig.default || componentConfig);
});

requireElement.keys().forEach((fileName) => {
  const componentConfig = requireElement(fileName);

  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, "$1"))
  );

  app.component(componentName, componentConfig.default || componentConfig);
});

requireWizard.keys().forEach((fileName) => {
  const componentConfig = requireWizard(fileName);

  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, "$1"))
  );

  app.component(componentName, componentConfig.default || componentConfig);
});

const i18n = createI18n({
  legacy: false,
  locale: "si",
  fallbackLocale: "si",
  messages: {
    si: si,
    en: en,
  },
});

app
  .use(head)
  .use(pageTitle({ mixin: true }))
  .use(i18n)
  .mount("#app");
