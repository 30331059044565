<template>
  <div class="banner container white bg-blue-500">
    <PictureVue :img="messages[locale].banner.img" class="full" />
    <div class="title z-index-1">
      <span class="text-xblk">{{ $t("banner.titleTop") }}</span>
      <div class="separator bg-green mt-2 mb-3"></div>
    </div>
    <h1 class="z-index-1 mb-8">{{ $t("banner.title") }}</h1>
    <div class="container full z-index-1">
      <div class="content-main mb-5">
        <div class="separator thin bg-gray-300 mb-5"></div>
        <span class="text-flash">{{ $t("banner.content-left") }}</span>
      </div>
      <div class="content-side flex flex-column">
        <div class="separator thin bg-gray-300 mb-5"></div>
        <b class="mb-8">{{ $t("banner.content-right") }}</b>
        <button
          class="scroll-to btn-primary flex mt-auto"
          data-id="#contact-us"
          ref="scroller"
          @click="scrollTo"
        >
          <span>{{ $t("banner.button") }}</span>
          <img src="@/assets/img/icons/arrow_down.svg" alt="Arrow down" />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import PictureVue from "../elements/PictureVue.vue";
import gsap from "gsap";
import { ScrollToPlugin } from "gsap/all";
import { onMounted, ref } from "vue";

gsap.registerPlugin(ScrollToPlugin);

const scroller = ref();
const scrollTo = function () {
  gsap.to(window, { duration: 1, scrollTo: scroller.value.dataset.id });
};

const { t, messages, locale } = useI18n();
</script>

<style lang="scss" scoped></style>
