<template>
  <div class="container company-swiper">
    <b class="title text-lg blue-dark">{{ $t("companySwiper.title") }}</b>
    <div ref="prev" class="swiper-button-prev">
      <img src="@/assets/img/icons/arrow_left_blue.svg" alt="" />
    </div>
    <div ref="swiper" class="swiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="logo in logos" :key="logo">
          <img :src="require('@/assets/img/company_logos/' + logo)" />
        </div>
      </div>
    </div>
    <div ref="next" class="swiper-button-next">
      <img src="@/assets/img/icons/arrow_right_blue.svg" alt="" />
    </div>
  </div>
</template>

<script setup>
import Swiper, { Autoplay, Navigation } from "swiper";
import { onMounted, ref } from "vue";
const logos = ref([
  "spar.svg",
  "petrol.svg",
  "atlantic.svg",
  "podravka.svg",
  "storck.svg",
  "pernot_ricard.svg",
  "vaillant.svg",
  "renault_group.svg",
  "dr_oetker.svg",
  "barilla.svg",
]);
const swiper = ref();
const next = ref();
const prev = ref();

onMounted(() => {
  new Swiper(swiper.value, {
    modules: [Navigation, Autoplay],
    slidesPerView: "auto",
    updateOnWindowResize: true,
    loop: true,
    speed: 500,
    resistanceRatio: 0.9,
    watchSlidesProgress: true,
    navigation: {
      nextEl: next.value,
      prevEl: prev.value,
    },
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
  });
});
</script>

<style lang="scss" scoped></style>
