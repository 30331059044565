<template>
  <div :class="[{ error: errorMessage }, 'form-item']">
    <div>
      <label :for="id">
        {{ label }}
      </label>
      <input
        v-bind="$attrs"
        type="file"
        :id="id"
        :name="name"
        @change="handler($event)"
      />
    </div>
    <div class="preview">
      <p v-if="!files">No files currently selected for upload</p>
      <ul v-else>
        <li v-for="file in files" :key="file">
          <BaseFilePreview
            :file="file"
            :preview="preview"
            :vertical="vertical"
          />
        </li>
      </ul>
    </div>

    <BaseErrorMessage v-if="errorMessage" id="image-uploads-error">
      {{ errorMessage }}
    </BaseErrorMessage>
  </div>
</template>

<script setup>
import { useField } from "vee-validate";
import { computed, inject, provide } from "vue";

const props = defineProps({
  label: {
    type: String,
    default: "Izberite datoteko za nalaganje",
  },
  id: {
    type: String,
    default: "file-uploads",
  },
  name: {
    type: String,
    default: "fileUploads",
  },
  props: {
    type: Array,
    default: null,
  },
  preview: {
    type: Boolean,
    default: true,
  },
  fileTypes: {
    type: Object,
    default() {
      return undefined;
    },
  },
  vertical: {
    type: Boolean,
    default: false,
  },
});

const files = computed(() => {
  let files;
  if (inputValue.value) {
    if (props.fileTypes) {
      files = inputValue.value.filter((file) => {
        if (validFileType(file)) return file;
      });
      if (files.length) return files;
      else return null;
    } else return inputValue;
  } else return null;
});

const validFileType = function (file) {
  if (file) {
    if (file.name.match(/\.heic$/i)) return "heic";
    return props.fileTypes.includes(file.type);
  } else {
    return false;
  }
};

provide("VALID", validFileType);

const formData = inject("FORM_DATA");

const { value: inputValue, handleChange, errorMessage } = useField(props.name);

const handler = function (event) {
  handleChange(event);
  formData.set(props.name, event.target.files[0]);
};
</script>

<style scoped>
#image-uploads {
  visibility: hidden;
  max-width: 1px;
}
</style>
