<template>
  <FormWizard
    :url="'send_email.php'"
    :buttonSubmit="messages[locale].contactForm.buttonSubmit"
  >
    <BaseFormStep>
      <BaseInput
        :label="$t('contactForm.fullname')"
        name="userName"
        placeholder=" "
      />
      <BaseInput
        :label="$t('contactForm.email')"
        name="userEmail"
        :lazy="true"
        placeholder=" "
      />
      <BaseInput
        :label="$t('contactForm.yourMsg')"
        name="content"
        :rows="10"
        placeholder=" "
      />
    </BaseFormStep>
  </FormWizard>
</template>

<script setup>
import { defineAsyncComponent } from "vue";
import { useI18n } from "vue-i18n";

const { messages, locale } = useI18n();
const FormWizard = defineAsyncComponent(() =>
  import("@/components/form/wizard/BaseFormWizard.vue")
);
</script>

<style lang="scss" scoped></style>
