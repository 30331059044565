<template>
  <div class="swiper-container container mb-5" :key="$i18n.locale">
    <div class="background full bg-gray-100"></div>
    <div ref="swiper" class="swiper z-index-1">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="slide in messages[locale].swiperBlock"
          :key="slide.info"
        >
          <PictureVue :img="slide.img" />
          <div class="infographic flex flex-column white">
            <div class="info-bg bg-blue-dark"></div>
            <span class="big">{{ slide.stat }}</span>
            <span class="small mb-5">{{ slide.info }}</span>
            <div class="icon-container">
              <img :src="slide.infographic" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="swiper-nav py-5">
      <div ref="prev" class="swiper-button-prev">
        <img src="@/assets/img/icons/arrow_left_blue.svg" alt="" />
      </div>
      <div ref="pagination" class="swiper-pagination"></div>
      <div ref="next" class="swiper-button-next">
        <img src="@/assets/img/icons/arrow_right_blue.svg" alt="" />
      </div>
    </div>
  </div>
</template>

<script setup>
import Swiper, { Navigation, Pagination, Autoplay } from "swiper";
import "swiper/css";
import PictureVue from "@/components/elements/PictureVue.vue";
import { useI18n } from "vue-i18n";
import { onMounted, onUpdated, ref } from "vue";

const swiper = ref();
const prev = ref();
const next = ref();
const pagination = ref();

const { messages, locale } = useI18n();

onMounted(() => {
  new Swiper(swiper.value, {
    modules: [Navigation, Pagination],
    slidesPerView: 1,
    spaceBetween: 30,
    updateOnWindowResize: true,
    loop: true,
    speed: 500,
    resistanceRatio: 0.9,
    pagination: {
      el: pagination.value,
      clickable: true,
    },
    navigation: {
      nextEl: next.value,
      prevEl: prev.value,
    },
  });
});

onUpdated(() => {
  new Swiper(swiper.value, {
    modules: [Navigation, Pagination],
    slidesPerView: 1,
    spaceBetween: 30,
    updateOnWindowResize: true,
    loop: true,
    speed: 500,
    resistanceRatio: 0.9,
    pagination: {
      el: pagination.value,
      clickable: true,
    },
    navigation: {
      nextEl: next.value,
      prevEl: prev.value,
    },
  });
});
</script>

<style lang="scss" scoped></style>
