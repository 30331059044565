<template>
  <header class="container bg-white" id="#header">
    <div class="container full z-index-1">
      <div class="fill bg-white"></div>
      <div class="logo flex bg-white items-start pb-8 pt-6">
        <img :src="messages[locale].logo" alt="BTC Logistični center" />
        <div
          class="spans flex flex-column blue ml-3"
          v-html="$t('header.logo')"
        ></div>
      </div>
    </div>
    <div class="background-clip"></div>
    <div class="nav container full z-index-2 pb-10 pt-6">
      <nav class="flex justify-end">
        <div :class="{ active: $i18n.locale === 'si' }">
          <input type="radio" id="si" value="si" v-model="$i18n.locale" />
          <label for="si">SI</label>
        </div>
        <span class="dot mx-1">·</span>
        <div :class="{ active: $i18n.locale === 'en' }">
          <input type="radio" id="en" value="en" v-model="$i18n.locale" />
          <label for="en">EN</label>
        </div>
      </nav>
    </div>
  </header>
</template>

<script setup>
import { watch } from "vue";
import { useI18n } from "vue-i18n";
const { messages, locale } = useI18n();
</script>

<style lang="scss" scoped></style>
