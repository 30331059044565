<template>
  <div class="contacts-block container">
    <h2 class="blue-dark">{{ t("contacts.title") }}</h2>
    <div class="separator bg-green mt-5 mb-10"></div>
    <ContactCard
      v-for="contact in messages[locale].contacts.cards"
      :contact="contact"
      :key="contact"
    />
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import ContactCard from "../elements/ContactCard.vue";

const { t, messages, locale } = useI18n();
</script>

<style lang="scss" scoped></style>
