const switchCards = [
  {
    title: "Technology",
    content: `
      <h3>Innovative. Sophisticated. Reliable.</h3>
      <ul>
        <li>40.000 m2 of warehouse facilities,</li>
        <li>12 m in height,</li>
        <li>different temperature regimes,</li>
        <li>40 entry/exit ramps (combinatory),</li>
        <li>pallet space load capacity of 900 kg,</li>
        <li>sprinkler system,</li>
        <li>technical security.</li>
      </ul>
      <h5>We use various interfaces to adapt to your IT system.</h5>
    `,
    img: {
      src: require("@/assets/img/technology.webp"),
      fb: require("@/assets/img/technology.jpg"),
      alt: "Technology",
    },
  },
  {
    reverse: true,
    title: "Services",
    content: `
      <h3>Flexible. Fast. Efficient.</h3>
      <ul>
        <li>pick-up and storage of goods,</li>
        <li>insurance of goods,</li>
        <li>all temperature regimes storage,</li>
        <li>pick & pack,</li>
        <li>goods shipping,</li>
        <li>order administration,</li>
        <li>returnable packaging management,</li>
        <li>labelling, display assembling, repacking,</li>
        <li>local and international distribution,</li>
        <li>planning optimal transport routes,</li>
        <li>a modern information system for warehouse and transport management,</li>
        <li>advanced technological solutions for optimisation of internal logistics processes,</li>
        <li>flexible working hours,</li>
        <li>experienced team of professionally trained logistics experts.</li>
      </ul>
      <h5>The quality of our services is our greatest priority and the satisfaction of our business partners our foremost goal.</h5>
    `,
    img: {
      src: require("@/assets/img/services.webp"),
      fb: require("@/assets/img/services.jpg"),
      alt: "Services",
    },
  },
  {
    title: "Energy efficiency",
    content: `
      <h3>Environmentally friendly. Green. Innovative.</h3>
      <ul>
        <li>photovoltaic power station on the rooftop (6,000 m²),</li>
        <li>green roof above the facility office building (4,500 m²),</li>
        <li>heating with a heat pump (water-to-water),</li>
        <li>two water boreholes,</li>
        <li>smart LED lighting.</li>
      </ul>
      <h5>The building's energy efficiency is supported by sophisticated technological solutions.</h5>
    `,
    img: {
      src: require("@/assets/img/foto7.webp"),
      fb: require("@/assets/img/foto7.jpg"),
      alt: "Sustainability",
    },
  },
];

export default switchCards;
