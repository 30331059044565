const switchCards = [
  {
    title: "Tehnologija",
    content: `
      <h3>Inovativna. Dovršena. Zanesljiva.</h3>
      <ul>
        <li>Skladiščna površina: 40.000 m²,</li>
        <li>višina: 12 m,</li>
        <li>zagotavljanje različnih temperaturnih režimov,</li>
        <li>40 vhodno/izhodnih ramp (kombinirane),</li>
        <li>nosilnost paletnega mesta: 900 kg,</li>
        <li>sistem pršilnikov,</li>
        <li>tehnično varovanje.</li>
      </ul>
      <h5>Z vmesniki se prilagodimo vašemu informacijskemu sistemu.</h5>
    `,
    img: {
      src: require("@/assets/img/technology.webp"),
      fb: require("@/assets/img/technology.jpg"),
      alt: "Tehnologija",
    },
  },
  {
    reverse: true,
    title: "Storitev",
    content: `
      <h3>Prilagojena. Hitra. Učinkovita.</h3>
      <ul>
        <li>Prevzem in uskladiščenje blaga,</li>
        <li>zavarovanje blaga,</li>
        <li>skladiščenje pri vseh temperaturnih režimih,</li>
        <li>komisioniranje,</li>
        <li>odprema blaga za dostavo,</li>
        <li>administracija naročil,</li>
        <li>upravljanje vračljive embalaže,</li>
        <li>etiketiranje, deklariranje, prepakiranje,</li>
        <li>domača in mednarodna distribucija,</li>
        <li>načrtovanje optimalnih transportnih poti,</li>
        <li>sodoben informacijski sistem za upravljanje skladišča in transporta,</li>
        <li>napredne tehnološke rešitve za optimizacijo procesov interne logistike,</li>
        <li>prilagodljiv delovni čas,</li>
        <li>izkušena ekipa strokovno usposobljenih logistov.</li>
      </ul>
      <h5>Kakovost storitve je naša največja prioriteta, saj nam je zadovoljstvo naših poslovnih partnerjev najpomembnejše.</h5>
    `,
    img: {
      src: require("@/assets/img/services.webp"),
      fb: require("@/assets/img/services.jpg"),
      alt: "Storitve",
    },
  },
  {
    title: "Energetska učinkovitost",
    content: `
      <h3>Okolju prijazna. Zelena. Inovativna.</h3>
      <ul>
        <li>Fotovoltaična elektrarna na strehi (6.000 m²),</li>
        <li>zelena streha nad pisarniškim delom objekta (4.500 m²),</li>
        <li>ogrevanje s toplotno črpalko (voda-voda),</li>
        <li>lastni vrtini za vodo,</li>
        <li>pametna LED-osvetlitev.</li>
      </ul>
      <h5>Energetska učinkovitost objekta podprta s sodobnimi tehnološkimi rešitvami.</h5>
    `,
    img: {
      src: require("@/assets/img/foto7.webp"),
      fb: require("@/assets/img/foto7.jpg"),
      alt: "Trajnost",
    },
  },
];

export default switchCards;
