const contactForm = {
  title: "Pošljite povpraševanje",
  para: "Sporočite nam svoje želje o storitvah, ki vas zanimajo. Odgovorili  vam bomo v najkrajšem času.",
  errorMessage: {
    title: "Napaka",
    message: "Nekje se je zalomilo, poskusite znova.",
  },
  successMessage: {
    title: "Poslano",
    message: "Sporočilo je bilo uspešno poslano!",
  },
  buttonSubmit: "Pošljite",
  fullname: "Ime in priimek / Naziv podjetja",
  email: "Elektronski naslov",
  yourMsg: "Vaše sporočilo",
  terms: "Strinjam se s pogoji",
  dialogClose: "Zapri",
};

export default contactForm;
