<template>
  <div class="container certificates pt-11 mb-16">
    <div class="flex justify-between">
      <div class="picture-container">
        <img src="@/assets/img/icons/haccp.png" alt="haccp" />
      </div>
      <div class="picture-container">
        <img src="@/assets/img/icons/siq.png" alt="siq" />
      </div>
      <div class="picture-container">
        <img src="@/assets/img/icons/green_hand.png" alt="green hand" />
      </div>
      <div class="picture-container">
        <img src="@/assets/img/icons/bureau_veritas.png" alt="bureau veritas" />
      </div>
    </div>
  </div>
  <footer class="container bg-blue-dark white pt-8 pb-12">
    <div class="content-main">
      <span class="h4">{{ $t("footer.title") }}</span>
      <div class="separator thin bg-green mt-2 mb-3"></div>
      <p>{{ $t("footer.body") }}</p>
    </div>
    <div class="content-side">
      <img :src="messages[locale].logo" alt="BTC Logo" />
    </div>
    <div class="footer-bottom flex text-xs pt-5 mt-8">
      <span>©</span>
      <a class="blue-light" :href="$t('footer.primaryLink')" target="_blank"
        >BTC d.d.</a
      >
      <span>·</span>
      <a class="blue-light" :href="$t('footer.termsLink')" target="_blank">{{
        $t("footer.terms")
      }}</a>
    </div>
  </footer>
</template>

<script setup>
import { useI18n } from "vue-i18n";
const { messages, locale } = useI18n();
</script>

<style lang="scss" scoped></style>
