import switchCards from "./componentsSi/switchCards";
import swiperBlock from "./componentsSi/swiperBlock";
import contactCards from "./componentsSi/contactCards";
import errorMessages from "./componentsSi/errorMessages";
import contactForm from "./componentsSi/contactForm";
import warehouseCards from "./componentsSi/warehouseCards";
import footer from "./componentsSi/footer";
import meta from "./componentsSi/meta";
import cookies from "./componentsSi/cookies";

const si = {
  logo: require("@/assets/img/logo.svg"),
  mail: "{account}{'@'}{domain}",
  header: {
    logo: `
      <span>Logistični</span>
      <span>center</span>
      <span>Zalog</span>
    `,
  },
  banner: {
    img: {
      src: require("@/assets/img/foto1.webp"),
      fb: require("@/assets/img/foto1.jpg"),
      alt: "Banner slika",
    },
    titleTop: "NOVO",
    title: "Tehnološko sodoben in energetsko učinkovit logistični center",
    "content-left":
      "Logistični center v središču Slovenije ter na stičišču regionalnih in mednarodnih oskrbovalnih poti.",
    "content-right":
      "Potrebujete nov skladiščni prostor ali logistične storitve?",
    button: "POŠLJITE POVPRAŠEVANJE",
  },
  "basic-card": [
    {
      title: "NOV LOGISTIČNI CENTER",
      main: `
        <p>V družbi BTC smo v letu 2024 obstoječim kapacitetam več kot 100.000 paletnih mest dodali še 50.000 novih v tehnološko sodobnem in energetsko učinkovitem Logističnem centru Zalog. Nahaja se v Sloveniji, v glavnem mestu Ljubljana, s strateško pozicijo v neposredni bližini avtocestnega križa, kar zagotavlja odlično izhodišče za optimizacijo lokalnih in regionalnih transportnih poti.</p>
        <p>Skladiščni objekt je namenjen predvsem skladiščenju in manipulaciji izdelkov vsakdanje rabe (FMCG) z možnostjo prilagoditve kapacitet za družbe, ki delujejo na področju e-trgovine.</p>
      `,
      side: `
        <li><b>Izjemna lokacija na stičišču evropskih transportnih poti.</b></li>
        <li><b>Odlično izhodišče za izvoz v druge države Srednje Evrope.</b></li>
        <li><b>Možnost sodelovanja na področju logistike za e-trgovino.</b></li> 
        <li><b>Našim kakovostnim storitvam zaupajo globalni poslovni partnerji.</b></li>  
      `,
    },
  ],
  swiperBlock: swiperBlock,
  map: {
    title: "Lokacija",
    name: "BTC Logistični center Zalog",
    address: "Agrokombinatska cesta 63",
    city: "1000 Ljubljana",
    tel: "+386 1 585 11 88",
    main: `
      <h3>Strateška. Tranzitna. Intermodalna.</h3>
      <ul>
        <li>Na stičišču oskrbovalnih poti ter mednarodnih tranzitnih logističnih verig (Srednje in Vzhodne Evrope ter Adriatic regije),</li>
        <li>v neposredni bližini železniškega kontejnerskega terminala (7 km),</li>
        <li>enostavna povezava z mednarodnim tovornim pristaniščem Luka Koper (110 km) in glavnim letališčem Ljubljana (38 km),</li>
        <li>v neposredni bližini BTC Cityja, enega največjih nakupovalnih, poslovnih, rekreativno-zabaviščnih in kulturnih središč v Evropi, ter sedeža družbe BTC.</li>
      </ul>
      <h5>Strateška lokacija na stičišču evropskih transportnih poti.</h5>
    `,
  },
  contacts: {
    title: "Stopimo v stik",
    cards: contactCards,
  },
  companySwiper: {
    title: "Reference",
  },
  switchCards: switchCards,
  errorMessages: errorMessages,
  contactForm: contactForm,
  warehouseTitle: "BTC Logistika upravlja",
  warehouse: warehouseCards,
  footer: footer,
  meta: meta,
  cookies: cookies,
};

export default si;
